import React from "react";
import "./App.css";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import TestOurTech_thankyou from "./Components/TestOurTech_thankyou";
import RegisterForm from "./Components/RegisterForm";
import RegisterFormBostonFam from "./Components/RegisterFormBostonFam";

function App() {
  if (process.env.REACT_APP_STAGE === "PROD")
    console.log = function no_console() {};
  const ToHome = () => {
    return <Redirect to="/" />;
  };
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={RegisterForm} />
        <Route exact path="/bostonfam" component={RegisterFormBostonFam} />
        <Route exact path="/morris" component={RegisterForm} />
        <Route
          exact
          path="/TestOurTech_thankyou"
          component={TestOurTech_thankyou}
        />

        <Route exact path="*" component={ToHome} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
