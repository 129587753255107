import { React, useRef, useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./style.css";
import Header from "./Header";
import Footer from "./Footer";

import swal from "sweetalert";
// import Recaptcha from "react-recaptcha";
import LogService from "../services/log.service";
import RegisterService from "../services/register.service";

function RegisterFormBostonFam(props) {
  const [fields, setFields] = useState({
    sales_name: "",
    name: "",
    lname: "",
    emailid: "",
    showroom: "",
    address: "",
    phone: "",
    question: "",
    mattress_model: "",
    grid: "",
  });

  const [errors, setErrors] = useState({});
  const [captcha, setCaptcha] = useState(false);
  const [value_userform, setValue_userform] = useState([]);

  const [website, setWebsite] = useState(window.location.href);

  useEffect(() => {
    async function logCreate() {
      try {
        await LogService.createLog(website);
      } catch (e) {
        console.log(e);
      }
    }
    logCreate();
  }, [website]);

  localStorage.setItem("isRegister", false);
  const ref = useRef();
  useEffect(() => {
    test();
  }, []);

  async function test() {
    let errors = {};

    try {
      //FIXME remove this service if not used
      const response = await RegisterService.getStates();
      var value = response.data.data;
      this.setState({
        value: value,
      });
      if (response.data.status === 429 || response.data.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
      if (value.includes("redirect")) {
      } else {
        errors["questionnare"] = response.data.response;
      }
    } catch (err) {
      if (err.status === 429 || err.status === "429") {
        alert("Max Limit Reached. Please wait.");
      }
    }
  }

  const pasted = (e) => {
    e.preventDefault();
    return false;
  };

  const onlyNumbers = (evt) => {
    const { name, value } = evt.target;
    evt.target.value = evt.target.value.replace(/[^0-9]+/g, "");

    setFields((prevValue) => {
      return {
        ...prevValue,
        [name]: evt.target.value,
      };
    });
  };

  const checkedValue = (e) => {
    let value = "yes";
    if (e === false || e === "") {
      value = "no";
    }
    return value;
  };

  const handleChange = (e) => {
    let { value, checked, name } = e.target;
    if (name === "showroom" && value === "true") {
      value = "";
    }
    if (name !== "question") {
      setFields((prevValue) => {
        return {
          ...prevValue,
          [name]: value,
        };
      });
    } else {
      setFields((prevValue) => {
        return {
          ...prevValue,
          [name]: checked,
        };
      });
    }
  };

  const saveErrors = (err) => {
    setErrors(err);
  };

  // const verifyCallback = (response) => {
  //   let errors = {};
  //   if (response) {
  //     setCaptcha({
  //       captcha: true,
  //     });
  //   } else {
  //     setErrors(errors);
  //     return false;
  //   }
  // };

  const submituserRegistrationForm = (e) => {
    e.preventDefault();
    if (validate()) {
      let data = {
        sales_name: fields?.sales_name,
        fname: fields?.name,
        lname: fields?.lname,
        email: fields?.emailid,
        showroom: fields?.showroom,
        address: fields?.address,
        phone: fields?.phone,
        grid: fields?.grid,
        mattress_model: fields?.mattress_model,
        question: checkedValue(fields?.question),
        reference: window.location.pathname.replace("/", ""),
      };

      var username = process.env.REACT_APP_API_USERNAME;
      var password = process.env.REACT_APP_API_PASSWORD;
      try {
        try {
          LogService.createFormLog(website, "POST", data);
        } catch (e) {
          console.log(e);
        }

        const responseuserform = RegisterService.regData(data);
        responseuserform
          .then((response) => {
            LogService.apiResLog(website, JSON.stringify(response.data));

            var valueUserform = response?.data?.status;
            setValue_userform(valueUserform);
            localStorage.removeItem("isRegister");
            swal({
              title: "Successful!",
              text: "Form Submitted!",
              type: "success",
              icon: "success",
            }).then(() => {
              props.history.push({
                pathname: "/TestOurTech_thankyou",
                state: {
                  sales_name: fields?.sales_name,
                  name: fields.name,
                  lname: fields.lname,
                  emailid: fields.emailid,
                  showroom: fields.showroom,
                  address: fields?.address,
                  phone: fields?.phone,
                  grid: fields?.grid,
                  mattress_model: fields?.mattress_model,
                  question: fields?.question,
                  reference: window.location.pathname.replace("/", ""),
                },
              });
            });
            if (value_userform?.includes("redirect")) {
            }
          })
          .catch((err) => {
            LogService.apiResLog(website, JSON.stringify(err.response.data));

            let errorData = {};
            errorData["sales_name"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.sales_name &&
              err?.response?.data?.message.sales_name[0];
            errorData["name"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.fname &&
              err?.response?.data?.message.fname[0];
            errorData["lname"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.lname &&
              err?.response?.data?.message.lname[0];
            errorData["emailid"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.email &&
              err?.response?.data?.message.email[0];
            errorData["showroom"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.showroom &&
              err?.response?.data?.message.showroom[0];
            errorData["address"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.address &&
              err?.response?.data?.message.address[0];
            errorData["phone"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.phone &&
              err?.response?.data?.message.phone[0];
            errorData["mattress_model"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.mattress_model &&
              err?.response?.data?.message.mattress_model[0];
            errorData["grid"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.grid &&
              err?.response?.data?.message.grid[0];
            errorData["checkederrortwo"] =
              err?.response?.data?.message &&
              err?.response?.data?.message.size &&
              err?.response?.data?.message.size[0];
            saveErrors(errorData);
            ref?.current?.focus();
          });
      } catch (err) {
        if (err.response.status == 429 || err.response.status == "429") {
          alert("Max Limit Reached. Please wait.");
        }
        let errorData = {};
        errorData["sales_name"] = err?.response?.data?.message.sales_name[0];
        errorData["name"] = err?.response?.data?.message.fname[0];
        errorData["lname"] = err?.response?.data?.message.lname[0];
        errorData["emailid"] = err?.response?.data?.message.emailid[0];
        errorData["showroom"] = err?.response?.data?.message.showroom[0];
        errorData["address"] = err?.response?.data?.message.address[0];
        errorData["phone"] = err?.response?.data?.message.phone[0];
        errorData["mattress_model"] =
          err?.response?.data?.message.mattress_model[0];
        errorData["grid"] = err?.response?.data?.message.grid[0];

        saveErrors(errorData);
      }
    }
  };
  const validate = (e) => {
    let errors = {};
    let isformIsValid = true;
    // if (!captcha) {
    //   isformIsValid = false;
    //   errors["checkederrorcaptcha"] = "Captcha not verified";
    //   saveErrors(errors);
    //   return isformIsValid;
    // }
    // if (!i_agree) {
    //   isformIsValid = false;
    //   errors["checkederror"] = "Select the checkbox";
    //   setErrors(errors);

    //   return false;
    // }

    return isformIsValid;
  };
  // const onLoadRecaptcha = () => {};

  return (
    <>
      <div className="container-fluid">
        <Header />
        <div className="header_lower">
          Register your customer to receive their complimentary $25 Mastercard
          Reward Card or 30oz Stanley Tumbler!
        </div>
        <div id="main-registration-container">
          <div className="white-box form">
            {/* <h1>Register to score your free Reward Card!</h1>
            <hr /> */}
            <form
              name="userRegistrationForm"
              className="form-outer"
              onSubmit={submituserRegistrationForm}
            >
              <div className="row">
                <div className="col-md-6">
                  <label className="formsub">
                    Customer First Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Customer First Name"
                    style={errors.name ? { border: "1px solid red" } : null}
                    name="name"
                    id="fname"
                    className="searchBox_deals vc-validate"
                    ref={errors.name ? ref : null}
                    value={fields.name || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">{errors.name}</div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    Customer Last Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="lname"
                    style={
                      errors.lname && !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    placeholder="Customer Last Name"
                    id="lname"
                    className="searchBox_deals vc-validate"
                    value={fields.lname || ""}
                    ref={errors.lname && !errors.name ? ref : null}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.lname && !errors.name ? errors.lname : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Customer Email <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="emailid"
                    ref={
                      errors.emailid && !errors.lname && !errors.name
                        ? ref
                        : null
                    }
                    style={
                      errors.emailid && !errors.lname && !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    id="emailid"
                    placeholder="Customer Email"
                    value={fields.emailid || ""}
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.emailid && !errors.lname && !errors.name
                      ? errors.emailid
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Customer Phone# <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    id="phone"
                    style={
                      errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red" }
                        : null
                    }
                    name="phone"
                    placeholder="Customer Phone"
                    value={fields.phone || ""}
                    ref={
                      errors.phone &&
                      !errors.emailid &&
                      !errors.address &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    onChange={handleChange}
                    onKeyUp={onlyNumbers}
                    onPaste={pasted}
                  />
                  <div className="errorMsg">
                    {errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.phone
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label required className="formsub">
                    Customer Address <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    name="address"
                    placeholder="Customer Address"
                    id="address"
                    className="searchBox_deals vc-validate"
                    autoFocus=""
                    value={fields.address || ""}
                    onChange={handleChange}
                    style={
                      errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                  />
                  <div className="errorMsg">
                    {errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.address
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Showroom Location <span className="red_point">*</span>
                  </label>

                  <select
                    style={
                      errors.showroom &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.showroom &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    name="showroom"
                    id="showroom"
                    className="select_1 dropdown"
                    onChange={handleChange}
                  >
                    <option value>Select Showroom Location</option>

                    <option value="Appleton">Appleton</option>
                    <option value="Green Bay">Green Bay</option>
                    <option value="Greenfield">Greenfield</option>
                    <option value="Janesville">Janesville</option>
                    <option value="Kenosha">Kenosha</option>
                    <option value="Madison">Madison</option>
                    <option value="Marshfield">Marshfield</option>
                    <option value="Oshkosh">Oshkosh</option>
                    <option value="Pewaukee">Pewaukee</option>
                    <option value="Plover">Plover</option>
                    <option value="Rhinelander">Rhinelander</option>
                    <option value="Richfield">Richfield</option>
                    <option value="Stevens Point">Stevens Point</option>
                    <option value="Wausau">Wausau</option>
                  </select>
                  <div className="errorMsg">
                    {errors.showroom &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.showroom
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    Sales Associate Name <span className="red_point">*</span>
                  </label>
                  <input
                    type="text"
                    placeholder="Sale Associate Name"
                    style={
                      errors.sales_name &&
                      !errors.showroom &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    name="sales_name"
                    id="sales_name"
                    className="searchBox_deals vc-validate"
                    ref={
                      errors.sales_name &&
                      !errors.showroom &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    value={fields.sales_name || ""}
                    autoFocus=""
                    onChange={handleChange}
                  />
                  <div className="errorMsg">
                    {errors.sales_name &&
                    !errors.showroom &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.sales_name
                      : null}
                  </div>
                </div>

                <div className="col-md-6">
                  <label className="formsub">
                    What was your favorite Purple mattress model?{" "}
                    <span className="red_point">*</span>
                  </label>
                  <select
                    style={
                      errors.mattress_model &&
                      !errors.sales_name &&
                      !errors.showroom &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.mattress_model &&
                      !errors.sales_name &&
                      !errors.showroom &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    name="mattress_model"
                    id="mattress_model"
                    className="select_1 dropdown"
                    onChange={handleChange}
                  >
                    <option value="">
                      Select your favorite Purple mattress model
                    </option>

                    <option value="Rejuvenate Premier">
                      Rejuvenate Premier
                    </option>
                    <option value="Rejuvenate">Rejuvenate</option>
                    <option value="Restore Premier">Restore Premier</option>
                    <option value="Restore Plus">Restore Plus</option>
                    <option value="Purple Plus">Purple Plus</option>
                    <option value="The Purple Mattress">
                      The Purple Mattress
                    </option>
                  </select>

                  <div className="errorMsg">
                    {errors.mattress_model &&
                    !errors.sales_name &&
                    !errors.showroom &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.mattress_model
                      : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label className="formsub">
                    What did like most about the Gel Flex Grid:{" "}
                    <span className="red_point">*</span>
                  </label>
                  <select
                    style={
                      errors.grid &&
                      !errors.mattress_model &&
                      !errors.sales_name &&
                      !errors.showroom &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? { border: "1px solid red", color: "black" }
                        : { color: "black" }
                    }
                    ref={
                      errors.grid &&
                      !errors.mattress_model &&
                      !errors.sales_name &&
                      !errors.showroom &&
                      !errors.address &&
                      !errors.phone &&
                      !errors.emailid &&
                      !errors.lname &&
                      !errors.name
                        ? ref
                        : null
                    }
                    name="grid"
                    id="grid"
                    className="select_1 dropdown"
                    onChange={handleChange}
                  >
                    <option value>Select an option</option>

                    <option value="Comfort">Comfort</option>
                    <option value="Support">Support</option>
                    <option value="Air Flow">Air Flow</option>
                    <option value="All of the Above">All of the Above</option>
                  </select>

                  <div className="errorMsg">
                    {errors.grid &&
                    !errors.mattress_model &&
                    !errors.sales_name &&
                    !errors.showroom &&
                    !errors.address &&
                    !errors.phone &&
                    !errors.emailid &&
                    !errors.lname &&
                    !errors.name
                      ? errors.grid
                      : null}
                  </div>
                </div>
                <br />

                <br />
                <div className="clearfix"></div>
              </div>

              <div className="alert alert-info">
                Limit 1 per household, while supplies last. Some restrictions
                apply, see store for detail.
              </div>
              <input
                type="submit"
                name="submit"
                id="submit"
                value="SUBMIT"
                className="sub-btn1 button Formfirst"
              />
            </form>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}
export default RegisterFormBostonFam;
